<template>
    <div class="pb-5">
        <v-title title="Artwork Check"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Artwork Check Queue</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">

            <dtp-status class="mt-n4 mb-4"></dtp-status>

            <assigned-orders></assigned-orders>

            <dtp-queue></dtp-queue>

        </div>
    </div>
</template>

<script>

import DtpQueue from '@/components/elements/artwork/DtpQueue'
import AssignedOrders from '@/components/elements/artwork/AssignedOrders'
import DtpStatus from '@/components/elements/artwork/DtpStatus'
import { mapActions } from 'vuex'

export default {
    name: 'ArtworkCheck',
    components: { DtpQueue, AssignedOrders, DtpStatus },
    methods: {
        ...mapActions('order', ['checkAssignedOrder']),
    },
    created () {
        this.checkAssignedOrder()
    },
}
</script>
