<template>
    <div>
        <h2>
            Tasks waiting for filecheck
            <template>
                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
            </template>
        </h2>
        <merchant-data-table class="shadow" style="height: auto;" :options="options" :url="url" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import SlaStatus from '@/components/elements/table/SlaStatus'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import moment from 'moment-timezone'
import Currency from '@/components/elements/Currency'

const userTimeZone = moment.tz.guess()
export default {
    name: 'DtpQueue',
    components: { MerchantDataTable, Pagination },
    data () {
        return {
            pagination: {},
            amountOfDisplayedItems: 50,
            options: {
                columns: {
                    'Orderline Id': {
                        field: 'orderLineNumber',
                        valueFormatter: ({ value, data }) => `${value}${data.quote ? ' (quote)' : ''}`,
                        link: (value, data) => {
                            return {
                                name: 'OrderLineArtworkCheck',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                    orderLineId: data.orderLineId,
                                },
                            }
                        },
                        sortable: true,
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Total': {
                        field: 'orderLinePrice',
                        sortable: true,
                        filter: false,
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.data.currency,
                            }
                        },
                        menuTabs: [],
                    },
                    'C-SLA': {
                        field: 'csla.customerSla',
                        cellRendererFramework: SlaStatus,
                        sortable: true,
                        menuTabs: [],
                        cellRendererParams: (params) => {
                            return {
                                sign: params.value.sign,
                            }
                        },
                    },
                    'SKU': {
                        field: 'sku',
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            clearButton: true,
                            suppressAndOrCondition: true,
                        },
                        sortable: true,
                    },
                    'Start Time': {
                        field: 'lastActionTime',
                        valueGetter: params => moment(params.data.lastActionTime).tz(userTimeZone).format('YYYY-MM-DD, HH:mm:ss'),
                        sortable: true,
                        menuTabs: [],
                    },
                    'Cut off': {
                        field: 'sla.supplierSlaTime',
                        valueGetter: params => {
                            return moment(params.data.sla.supplierSlaTime).tz(userTimeZone).format('HH:mm')
                        },
                        sortable: false,
                        menuTabs: [],
                    },
                    'S-SLA': {
                        field: 'sla.supplierSla',
                        cellRendererFramework: SlaStatus,
                        cellRendererParams: (params) => {
                            return {
                                sign: params.data.sla.sign,
                                isFormatted: false,
                                isColored: true,
                            }
                        },
                        sortable: true,
                        menuTabs: [],
                    },
                    'Canva Design': {
                        field: 'isCanvaDesign',
                        valueFormatter: params => {
                            return params.data.isCanvaDesign ? 'Yes' : '-'
                        },
                        menuTabs: [],
                    },
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: ['filterMenuTab'] },
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.ORDERS.ARTWORK_QUEUE.replace('{accountId}', this.$store.state.auth.user.accountId)
        },
    },
    methods: {
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>

<style lang="scss" scoped>
    h2 {
        font-weight: normal;
        display: flex;
        align-items: center;

        .c-default-table__pagination {
            flex: 1;
            margin-bottom: 0;
        }

        /deep/ .c-default-table__pagination-info {
            font-size: 14px;
        }
    }
</style>
