<template>
    <div>
        <h2>Tasks in progress</h2>
        <merchant-data-table class="shadow" style="height: unset; min-height: 200px;" :options="options" :url="url" :isDefaultSize="false" ref="table" :isNotApplicationBound="true"></merchant-data-table>
    </div>
</template>

<script>
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import SlaStatus from '@/components/elements/table/SlaStatus'
import CONFIG from '@root/config'

export default {
    name: 'AssignedOrders',
    components: { MerchantDataTable },
    data () {
        return {
            url: CONFIG.API.ROUTES.ORDERS.ASSIGNED_ORDERS.replace('{agentId}', this.$store.state.auth.user.accountId),
            options: {
                columns: {
                    'Getting Checked by': { field: 'accountName' },
                    'Orderline Id': {
                        field: 'orderLineNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderLineArtworkCheck',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                    orderLineId: data.orderLineId,
                                },
                            }
                        },
                    },
                    'SKU': { field: 'sku' },
                    'C-SLA': {
                        field: 'customerSla',
                        cellRendererFramework: SlaStatus,
                    },
                    'Time on task': {
                        field: 'timeOnTask',
                    },
                    'S-SLA': {
                        field: 'supplierSla',
                        cellRendererFramework: SlaStatus,
                        cellRendererParams: { isColored: true },
                    },
                },
                pagination: false,
                cacheBlockSize: -1,
                filter: false,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
    h2 {
        font-weight: normal;
    }
</style>
