<template>
    <div class="c-sla-status" :class="[ getSlaColor(params.value), params.isColored ? 'c-sla-status--colored' : '' ]">
        <span>{{ params.isFormatted ? formattedTime : params.value }}</span>
    </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'

export default Vue.extend({
    name: 'SlaStatus',
    data () {
        return {
        }
    },
    computed: {
        formattedTime () {
            if (!this.params.value) {
                return
            }
            const diff = this.params.value * 1000
            const minutes = moment.duration(diff).minutes()
            const hours = moment.duration(diff).hours()
            const days = moment.duration(diff).days()

            let dayValue = ''
            if (days) {
                dayValue = days + 'd'
            }

            return `${this.params.sign}${dayValue} ${hours}:${minutes}`
        },
    },
    methods: {
        getSlaColor (color) {
            const className = 'c-sla-status'
            let SLA_TIME = 0.45 // 45 minutes
            let value

            if (!String(color).includes(':')) {
                value = parseFloat(this.params.value) * (this.params.sign === '-' ? -1 : 1) // in seconds
                SLA_TIME = 2700 // 45 minutes
            } else {
                value = parseFloat(color.replace(':', '.'))
            }

            if (value < 0) {
                return `${className}--danger`
            } else if (value < SLA_TIME) {
                return `${className}--warning`
            } else {
                return `${className}--pending`
            }
        },
    },
})
</script>

<style lang="scss" scoped>
    $light-grey: #CBCBCB;
    $dark-grey: #666666;
    $green: #77BB22;
    $yellow: #FFC628;
    $red: #E00000;

    .c-sla-status {
        color: white;
        background: $light-grey;
        font-weight: bold;
        text-align: center;
        display: inline-block;
        font-size: 16px;
        line-height: 16px;
        padding: 10px 5px;
        margin-top: 5px;
        min-width: 60px;

        &--danger {
            background: $dark-grey;
        }

        &--pending, &--warning {
            background: $light-grey;
        }

        &--colored.c-sla-status {
            &--danger {
                background: $red;
            }

            &--warning {
                background: $yellow;
            }

            &--pending {
                background: $green;
            }
        }
    }
</style>
