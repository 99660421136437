<template>
    <mercur-card class="c-dtp-status">
        <div class="c-dtp-status__wrapper" v-if="dtpOverview">
            <div class="c-dtp-status__item c-dtp-status__pending">
                <p class="c-dtp-status__item-label">Pending Tasks</p>
                <p class="c-dtp-status__item-stat">{{ dtpOverview.pendingTasks }}</p>
            </div>
            <div class="c-dtp-status__item c-dtp-status__slas">
                <p class="c-dtp-status__item-label">Customer SLA (C-SLA)</p>
                <p class="c-dtp-status__item-stat "><span class="c-dtp-status__item-customer-breach">{{ dtpOverview.customerSla.breach }}</span> In Breach <span class="c-dtp-status__item-customer-ok">{{ dtpOverview.customerSla.ok }}</span> &lt;30m</p>
            </div>
            <div class="c-dtp-status__item c-dtp-status__slas">
                <p class="c-dtp-status__item-label">Supplier SLA (S-SLA)</p>
                <p class="c-dtp-status__item-stat"><span class="c-dtp-status__item-supplier-breach">{{ dtpOverview.supplierSla.breach }}</span> In Breach <span class="c-dtp-status__item-supplier-attention">{{ dtpOverview.supplierSla.attention }}</span> &lt; 45m  <span class="c-dtp-status__item-supplier-ok">{{ dtpOverview.supplierSla.ok }}</span> &gt; 45m</p>
            </div>
            <div class="c-dtp-status__item c-dtp-status__actions">
                <mercur-button class="btn btn-secondary text-white text-uppercase" v-if="!isAssigned" @click="assignOrder" :disabled="isLoading">
                    <i class="fas fa-step-forward"></i>
                    Start check
                </mercur-button>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'DtpStatus',
    data () {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapState('order', ['assignedOrder', 'dtpOverview']),
        isAssigned () {
            if (this.$store.state.order.assignedOrder) {
                return true
            }
            return false
        },
    },
    methods: {
        ...mapActions('order', ['assignFirstOrderToAgent', 'checkAssignedOrder', 'fetchDtpOverview']),
        assignOrder () {
            this.isLoading = true
            this.assignFirstOrderToAgent({
                accountId: this.$store.state.auth.user.accountId,
            }).then(() => {
                this.isLoading = false
                this.$router.push({
                    name: 'OrderLineArtworkCheck',
                    params: {
                        applicationId: this.assignedOrder.applicationId,
                        accountId: this.assignedOrder.accountId,
                        orderId: this.assignedOrder.orderId,
                        orderLineId: this.assignedOrder.orderLineIds.find((orderLineId) => { return orderLineId != null }),
                    },
                })
            })
        },
    },
    created () {
        this.fetchDtpOverview()
    },
}
</script>

<style lang="scss" scoped>

    .c-dtp-status {
        &__wrapper {
            display: flex;
        }

        &__item {
            padding: 0 40px;

            &:last-child {
                padding: 0;
                justify-content: center;
            }

            &-label {
                margin-bottom: 10px;
                margin-top: 0;
            }

            &-stat {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 12px;
            }

            &-customer-breach {
                background-color: #666666;
            }

            &-customer-ok {
                background-color: #CBCBCB;
            }

            &-supplier-breach {
                background-color: #E00000;
            }

            &-supplier-attention {
                background-color: #FFC628;
            }

            &-supplier-ok {
                background-color: #77BB22;
            }

        }

        &__item:nth-child(-n+3) {
            border-right: 1px solid #DFDFDF;
        }

        &__pending {

            .c-dtp-status__item-stat {
                font-size: 42px;
                line-height: 30px;
                font-weight: bold;
            }
        }

        &__slas {
            .c-dtp-status__item-stat span {
                text-align: center;
                width: 50px;
                color: white;
                font-weight: bold;
                font-size: 16px;
                padding: 6px 0;
                display: inline-block;
                margin-right: 5px;
                margin-left: 10px;
                vertical-align: middle;

                &:nth-child(1) {
                    margin-left: 0;
                }
            }
        }

        &__actions {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
</style>
